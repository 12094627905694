<template>
  <v-alert
    outlined
    color="info"
  >
    <v-tooltip
      bottom
      :disabled="!!landingpageSlug"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn
            class="mb-3 mr-3 w-full"
            color="info"
            outlined
            small
            :disabled="isLoading || !landingpageSlug"
            :loading="isLoading"
            @click="send"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-email-fast-outline
            </v-icon>
            {{ $t('buttons.invitation-preview.cta') }}
          </v-btn>
        </div>
      </template>
      <span>
        {{ $t('alerts.acquisition-booster.invitation-button.error-topic') }}
      </span>
    </v-tooltip>

    <p class="text-body-2">
      {{ $t('invitation-preview.title', { email: $auth.user.email }) }}
    </p>
  </v-alert>
</template>

<script>
import SEND_INVITATION_PREVIEW from '@/modules/leads/queries/SendInvitationPreview.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    landingpageSlug: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async send () {
      this.isLoading = true
      setTimeout(() => {
        this.sendInvitationPreview()
      }, 2000)
    },
    async sendInvitationPreview () {
      try {
        await this.$apollo.mutate({
          mutation: SEND_INVITATION_PREVIEW,
          variables: {
            input: {
              landingpageSlug: this.landingpageSlug
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.acquisition-booster.invitation-preview.success', { email: this.$auth.user.email }) })
      } catch (error) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.acquisition-booster.invitation-preview.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
