<template>
  <v-dialog
    v-model="dialog"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip, attrs }">
          <v-btn
            elevation="2"
            icon
            v-on="on"
          >
            <v-icon
              v-bind="attrs"
              v-on="onTooltip"
            >
              mdi-email-sync
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('lead.base-info.channel.resend') }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('lead.base-info.channel.resend') }}?</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-4">
        <span>
          {{ $t('lead.base-info.channel.resend-info') }}
        </span>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="dialog = false"
        >
          {{ $t('labels.cancel') }}
        </v-btn>

        <v-btn
          color="success"
          :disabled="isLoading"
          text
          @click="resendPendingSubscriptions"
        >
          {{ $t('labels.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import RESEND_PENDING_SUBSCRIPTIONS from './queries/ResendPendingSubscriptions.gql'
import LEAD from './queries/Lead.gql'

export default {
  props: {
    leadId: { type: String, required: true },
    channel: { type: Object, required: true }
  },
  data () {
    return {
      dialog: false,
      isLoading: false
    }
  },
  methods: {
    async resendPendingSubscriptions () {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: RESEND_PENDING_SUBSCRIPTIONS,
          variables: {
            input: {
              leadId: this.leadId,
              channelId: this.channel.channelId
            }
          },
          refetchQueries: [{ query: LEAD, variables: { id: this.leadId } }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.lead.base-info.channel.success-resend') })
        this.dialog = false
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.lead.base-info.channel.error-resend') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
