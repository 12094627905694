<template>
  <v-timeline-item
    color="userActivity"
    icon="mdi-information-variant"
    fill-dot
  >
    <v-row
      no-gutters
      align="center"
      class="pr-12 mb-2 mt-n2 d-flex justify-center"
      :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
    >
      <v-col md="8">
        <div class="subtitle-1">
          {{ $t(leadStatus[data.status]) }}
        </div>
        <div class="grey--text text--small">
          {{ $t('labels.by') }} {{ data.userName }}
        </div>
      </v-col>
      <v-col
        class="text-right grey--text text--small pr-8"
        :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
        md="4"
      >
        {{ formatDate(data.createdAt) }}
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import leadStatus from '@/lib/leadStatus'

export default {
  props: {
    data: { type: Object, default: null }
  },
  beforeCreate () {
    this.leadStatus = leadStatus
  }
}
</script>

<style scoped>
.text--small {
  font-size: 15px;
}
</style>
