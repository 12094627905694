<template>
  <v-card
    class="mt-4 pa-4"
    outlined
  >
    <h4 class="mb-1 subtitle-2">
      {{ $t('labels.commentary') }}
    </h4>
    <v-textarea
      v-model="text"
      rows="4"
      hide-details
      outlined
      dense
    />

    <div class="justify-end mt-3 d-flex">
      <v-btn
        color="primary"
        small
        :disabled="text === ''"
        @click="submit"
      >
        {{ $t('labels.add') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>

import LEAD from './queries/Lead.gql'
import ADD_COMMENTARY from './queries/AddCommentary.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    leadId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      text: ''
    }
  },
  methods: {
    async submit () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_COMMENTARY,
          variables: {
            leadId: this.leadId,
            text: this.text
          },
          refetchQueries: [
            { query: LEAD, variables: { id: this.leadId } }
          ]
        })

        if (data.response) this.text = ''

        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.lead.commentary.success') })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.lead.commentary.error') })
      }
    }
  }
}

</script>
