<template>
  <v-timeline-item
    color="leadActivity"
    icon="mdi-image-filter-hdr"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="mb-2 subtitle-1">
                {{ $t('plot-valuation.title-timeline') }}
              </div>
              <div
                v-if="conversion"
                class="grey--text"
              >
                {{ $t(plotTypeLabel) }}
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="conversion">
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('plot-valuation.conversion.labels.contact') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ name }}

                      <div class="grey--text text--darken-3 body-2">
                        {{ phone }}
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('plot-valuation.conversion.labels.callback-time-of-day') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ callbackTimeOfDay }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('labels.source') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ source }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('plot-valuation.conversion.labels.object-type') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t(plotTypeLabel) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('plot-valuation.conversion.labels.address') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ plotValuation.address.street }} {{ plotValuation.address.houseNumber }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ plotValuation.address.zip }} {{ plotValuation.address.city }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('plot-valuation.conversion.labels.land-area') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ plotValuation.landArea }} m²
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="4">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('plot-valuation.conversion.labels.water') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ plotValuation.conditions.water ? $t('labels.yes'):$t('labels.no') }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('plot-valuation.conversion.labels.road') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ plotValuation.conditions.road ? $t('labels.yes'):$t('labels.no') }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('plot-valuation.conversion.labels.power') }}:</v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ plotValuation.conditions.power ? $t('labels.yes'):$t('labels.no') }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('plot-valuation.conversion.labels.development') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t(developmentLabel) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <div class="justify-end d-flex">
            <v-btn
              small
              outlined
              color="primary"
              @click.prevent="downloadPlotValuation"
            >
              Export (.xml)
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import { exportPlotValuation } from '@/lib/export'
import { plotTypes } from '@/lib/plotTypes'
import CONVERSION from '../queries/Conversion.gql'
import { readableDevelopmentTypes } from '@/lib/developmentTypes'
import { conversionSource } from '@/lib/conversionSource'
import { timeToLabelMap } from '@/lib/callbackTimeOfDay'

export default {
  mixins: [currencyMixin],

  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      conversion: null,
      error: null
    }
  },
  computed: {
    plotValuation () {
      if (!this.conversion || !this.conversion.plotValuation) return
      return this.conversion.plotValuation
    },
    plotTypeLabel () {
      return plotTypes[this.plotValuation.type]
    },
    developmentLabel () {
      return readableDevelopmentTypes[this.plotValuation.development]
    },
    source () {
      if (!this.conversion) return
      return this.conversion.source || conversionSource[this.conversion.origin] || ''
    },
    name () {
      if (!this.conversion) return
      return `${this.conversion.personalData.firstname} ${this.conversion.personalData.lastname}`
    },
    phone () {
      if (!this.conversion) return
      return this.conversion.personalData.formattedPhone
    },
    callbackTimeOfDay () {
      if (!this.conversion) return
      return this.conversion?.plotValuation?.callbackTimeOfDay
        .map(time => this.$t(timeToLabelMap[time]))
        .join(', ')
    }
  },
  methods: {
    async downloadPlotValuation () {
      await exportPlotValuation({ id: this.conversion.id })
    }
  },
  apollo: {
    conversion: {
      query: CONVERSION,
      variables () {
        return {
          conversionId: this.data.details[this.data.type].conversionId
        }
      },
      error (error) {
        this.error = error.graphQLErrors[0].extensions.code
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
