<template>
  <div>
    <p class="text-subtitle-2 ma-0 pa-0">
      {{ $t('lead.crm.title', { crm: readableCrmName }) }}
    </p>
    <div v-if="isCrmActive">
      <div class="grey--text text--darken-1 caption">
        {{ $t('lead.crm.exported-at', { date: lastExportedAt }) }}
      </div>
    </div>
    <div
      v-if="!isCrmActive"
      class="grey--text text--darken-1 caption"
    >
      {{ $t('lead.crm.link-prefix') }}
      <a href="/settings/crm">{{ $t('lead.crm.link-text', { platform: readablePlatformName }) }}</a>
    </div>
    <div
      v-else-if="!lead.isDataProcessingPermissionGranted"
      class="grey--text text--darken-1 caption"
    >
      {{ $t('lead.crm.disclaimer', { crm: readableCrmName }) }}
    </div>
    <CrmSynchronization
      :lead="lead"
      :crm-label="readableCrmName"
      :disabled="!isCrmActive || !lead.isDataProcessingPermissionGranted"
    />
  </div>
</template>

<script>
import CrmSynchronization from './CrmSynchronization.vue'
import { sortByDate } from '@/lib/sort-functions'
import crmMixin from '@/mixins/crm'
import brandingMixin from '@/mixins/branding'

export default {
  components: { CrmSynchronization },
  mixins: [crmMixin, brandingMixin],
  props: {
    lead: {
      type: Object,
      default: null
    }
  },
  computed: {
    lastExportedAt () {
      const { activities = [] } = this.lead
      activities.sort(sortByDate('createdAt', 'desc'))
      const lastExported = activities.find(activity => activity.type === 'EXPORT_TO_CRM')
      return lastExported ? this.formatDate(lastExported.createdAt) : this.$t('labels.never')
    }
  }
}
</script>
