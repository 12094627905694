<template>
  <v-card outlined>
    <v-card-title class="pt-12 pb-2 pl-4 blue">
      <span class="white--text">
        {{ $t('acquisition-booster.title') }}
      </span>
    </v-card-title>

    <v-card-text
      v-if="isHoppermationActivated"
      class="py-2"
    >
      <span class="font-weight-bold">
        {{ $t('acquisition-booster.invitation.body-texts.0') }}
      </span><br>
      <p>{{ $t('acquisition-booster.invitation.body-texts.1', { leadName }) }}</p>
      <InvitationTopicSelect
        v-model.lazy="landingpageSlug"
        :lead-id="lead.id"
      />
      <span class="font-weight-bold">
        {{ $t('acquisition-booster.invitation.body-texts.2') }}
      </span><br>
      <p class="mb-0">
        {{ $t('acquisition-booster.invitation.body-texts.3', { leadName }) }}
      </p>
    </v-card-text>

    <v-card-text
      v-else
      class="pt-2 font-weight-bold"
    >
      {{ $t('acquisition-booster.invitation.body-texts.4') }}
    </v-card-text>

    <v-card-actions class="d-flex flex-column pt-0">
      <InvitationButton
        :lead-ids="[lead.id]"
        :landingpage-slug="landingpageSlug"
        :delay="3000"
        @invitationSent="invitationSent"
      />

      <InvitationPreview :landingpage-slug="landingpageSlug" />
    </v-card-actions>
  </v-card>
</template>
<script>
import MAIL_PROVIDER from '@/modules/leads/queries/MailProvider.gql'
import InvitationPreview from './InvitationPreview.vue'
import InvitationButton from './InvitationButton.vue'
import InvitationTopicSelect from './InvitationTopicSelect.vue'

export default {
  components: { InvitationPreview, InvitationButton, InvitationTopicSelect },
  props: {
    lead: { type: Object, default: null }
  },
  data () {
    return {
      landingpageSlug: null
    }
  },
  computed: {
    isHoppermationActivated () {
      return this.mailProvider === 'HOPPERMATION'
    },
    leadName () {
      return `${this.lead.firstname} ${this.lead.lastname}` || this.lead.email
    }
  },
  methods: {
    invitationSent () {
      this.landingpageSlug = null
    }
  },
  apollo: {
    mailProvider: {
      query: MAIL_PROVIDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: (data) => data.company.mailProvider
    }
  }
}
</script>
