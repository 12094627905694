<template>
  <v-card
    v-if="lead"
    class="mt-4 pa-4"
    outlined
  >
    <h4 class="mb-1 subtitle-2">
      {{ $t('lead.conversions.title') }}
    </h4>

    <v-chip
      v-for="topic in topics"
      :key="topic"
      class="mt-2 mr-2"
    >
      {{ topic }}
    </v-chip>

    <div v-if="!topics.length">
      {{ $t('lead.conversions.no-topics') }}
    </div>
  </v-card>
</template>

<script>
import CONVERSION from './queries/Conversion.gql'

export default {
  props: {
    lead: { type: Object, default: null }
  },
  data () {
    return { conversions: [] }
  },
  computed: {
    topics () {
      return [...new Set(this.conversions.map((conversion) => conversion.landingpage?.name).filter(Boolean))]
    }
  },
  async mounted () {
    this.conversions = await Promise.all(this.lead.conversions.map(({ id }) => this.getConversion(id)))
  },
  methods: {
    async getConversion (conversionId) {
      const { data: { conversion } } = await this.$apollo.query({
        query: CONVERSION,
        variables: { conversionId }
      })
      return conversion
    }
  }
}
</script>
