<template>
  <div
    v-if="id"
    class="d-flex justify-center w-full"
  >
    <div
      :id="id"
      class="lottie-player"
      :style="{'width': `${width}px`}"
    />
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  props: {
    finished: {
      type: Function,
      default: () => {}
    },
    color: {
      type: String,
      default: '#00c65a'
    },
    animationData: {
      type: Object,
      default: () => ({})
    },
    replacementColorsMap: {
      type: Array,
      default: () => []
    },
    loop: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 1000
    },
    width: {
      type: Number,
      default: 150
    },
    speed: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      animationTimeout: null,
      id: null
    }
  },

  beforeMount () {
    this.id = `player-${this._uid}`
  },
  async mounted () {
    if (this.animationData) {
      this.anim = this.animationData
      this.anim = lottie.loadAnimation({
        container: document.getElementById(this.id),
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
        animationData: this.animationData
      })
      this.anim.setSpeed(this.speed)
      this.anim.play()
      if (!this.duration) {
        this.anim.addEventListener('complete', () => this.finished())
      } else {
        this.animationTimeout = setTimeout(() => {
          this.finished()
        }, this.duration)
      }
    }
  },
  unmounted () {
    clearTimeout(this.animationTimeout)
  }
}
</script>
