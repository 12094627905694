<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
    persistent
  >
    <template #activator="{ on }">
      <div class="justify-end mt-3 d-flex">
        <v-btn
          class="error white--text"
          small
          v-on="on"
        >
          <v-icon
            class="mr-2"
            small
          >
            mdi-trash-can
          </v-icon>
          {{ $t('labels.delete') }}
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="error"
          dark
          flat
        >
          <v-toolbar-title>
            <v-icon
              medium
              left
            >
              mdi-alert
            </v-icon>
            {{ $t('labels.caution') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="dialog=false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text
        v-if="leadOrigin === 'CRM'"
        class="mt-4"
      >
        <p
          v-html="$t('delete-contact.warning-platform', { readableLead, readablePlatformName, readableCrmName })"
        />
        <a
          v-if="leadCrmLink"
          :href="leadCrmLink"
          target="_blank"
          rel="noopener noreferrer"
        >{{ $t('delete-contact.crm-link', { readableCrmName }) }}</a>
      </v-card-text>
      <v-card-text
        v-else
        class="mt-4"
      >
        <p
          v-html="$t('delete-contact.warning', { readableLead })"
        />
        <p>{{ $t('delete-contact.disclaimer') }}</p>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="dialog=false"
        >
          {{ $t('labels.cancel') }}
        </v-btn>
        <v-btn
          color="error"
          @click="deleteContact"
        >
          {{ $t('labels.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DELETE_LEAD from './queries/DeleteLead.gql'
import bus, { eventNames } from '@/lib/eventBus'
import crmMixin from '@/mixins/crm'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [crmMixin, brandingMixin],
  props: {
    lead: { type: Object, default: null }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    readableLead () {
      return `${this.lead.firstname} ${this.lead.lastname}` || this.lead.email
    },
    leadCrmLink () {
      return this.lead?.crmLink
    },
    leadOrigin () {
      return this.lead?.origin
    },
    leadId () {
      return this.lead?.id
    }
  },
  methods: {
    async deleteContact () {
      try {
        const { data: { response } } = await this.$apollo.mutate({
          mutation: DELETE_LEAD,
          variables: {
            id: this.leadId
          }
        })

        if (response) {
          this.dialog = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.delete-contact.success') })
          this.$router.push({ path: '/leads', replace: true })
        }
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-contact.error') })
      }
    }
  }
}
</script>
