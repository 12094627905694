<template>
  <v-timeline-item
    v-if="conversion"
    color="leadActivity"
    icon="mdi-file-document-outline"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="mb-2 subtitle-1">
                <span
                  v-if="conversion.download.postalShipping !== 'NONE'"
                  v-html="$t('lead-timeline.download.title-postal-shipping', { type })"
                />
                <span
                  v-else
                  v-text="$t('lead-timeline.download.title', { type })"
                />
                <span v-if="conversion.download.postalShipping === 'AUTOMATIC'">{{ $t('lead-timeline.download.title-postal-shipping-automatic') }}</span>
                <span v-if="conversion.download.postalShipping === 'MANUAL'">{{ $t('lead-timeline.download.title-postal-shipping-manual') }}</span>
              </div>
              <div
                v-show="true"
                class="grey--text"
              >
                {{ landingpageName }}
              </div>
              <div v-if="conversion.postalShipping !== 'NONE'">
                <div
                  v-if="conversion.personalData.address"
                  class="grey--text"
                >
                  {{ conversion.personalData.address.street }} {{ conversion.personalData.address.houseNumber }} •
                  {{ conversion.personalData.address.zip }} {{ conversion.personalData.address.city }}
                </div>
              </div>
            </v-col>
            <v-col
              class="text-right grey--text pr-2"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col md="5">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('labels.source') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ source }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="conversion.download.postalShipping !== 'NONE'"
                  class="px-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('labels.postal-shipping') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ postalShipping(conversion.download.postalShipping) }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col md="5">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('labels.personal-data') }}:
                    </v-list-item-title>
                    <div class="grey--text text--darken-3 body-2">
                      {{ name }}
                    </div>
                    <div class="grey--text text--darken-3 body-2">
                      {{ phone }}
                    </div>
                    <div
                      v-if="conversion.personalData.address"
                      class="grey--text text--darken-3 body-2"
                    >
                      <div>
                        {{ conversion.personalData.address.street }} {{ conversion.personalData.address.houseNumber }}
                      </div>
                      <div>
                        {{ conversion.personalData.address.zip }} {{ conversion.personalData.address.city }}
                      </div>
                      <div>
                        {{ country(conversion.personalData.address.country) }}
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row v-if="conversion.download.postalShipping === 'MANUAL'">
            <v-col class="font-smaller">
              <strong>{{ $t('labels.our-tip') }}:<br></strong>
              {{ $t('lead-timeline.download.tip-shipping') }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import CONVERSION from '../queries/Conversion.gql'
import { conversionSource } from '@/lib/conversionSource'
import countries from '@/mixins/countries'

const POSTAL_SHIPPING_TYPES = {
  AUTOMATIC: 'labels.postal-shipping.automatic',
  MANUAL: 'labels.postal-shipping.manual',
  NONE: 'labels.postal-shipping.none'
}

export default {
  mixins: [countries],
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      conversion: null
    }
  },
  computed: {
    type () {
      if (!this.conversion) return
      return this.conversion.landingpage.type === 'CHECKLIST' ? this.$t('labels.one-checklist') : this.$t('labels.one-guide')
    },
    source () {
      if (!this.conversion) return
      return this.conversion.source || conversionSource[this.conversion.origin] || ''
    },
    landingpageName () {
      if (!this.conversion) return
      return this.conversion.landingpage.name
    },
    name () {
      if (!this.conversion) return
      if (!(this.conversion.personalData.firstname && this.conversion.personalData.lastname)) return ''
      return `${this.conversion.personalData.firstname} ${this.conversion.personalData.lastname}`
    },
    phone () {
      if (!this.conversion) return
      if (!this.conversion.personalData.formattedPhone) return ''
      return this.conversion.personalData.formattedPhone
    }
  },
  methods: {
    postalShipping (type) {
      return POSTAL_SHIPPING_TYPES[type]
    },
    country (value) {
      return this.availableCountryItems[value.toUpperCase()]
    }
  },
  apollo: {
    conversion: {
      query: CONVERSION,
      variables () {
        return {
          conversionId: this.data.details[this.data.type].conversionId
        }
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
