export const InvitationContactFilter = Object.freeze({
  ALL: 'ALL',
  NEW: 'NEW',
  VALUATIONS: 'VALUATIONS',
  PROPERTY_REQUEST: 'PROPERTY_REQUEST',
  CUSTOM: 'CUSTOM',
  CRM: 'CRM'
})

export const ReadableInvitationContactFilter = Object.freeze({
  ALL: 'labels.acquisition-booster.contact-filter.all',
  NEW: 'labels.acquisition-booster.contact-filter.new',
  VALUATIONS: 'labels.acquisition-booster.contact-filter.valuations',
  PROPERTY_REQUEST: 'labels.acquisition-booster.contact-filter.property-request',
  CUSTOM: 'labels.acquisition-booster.contact-filter.custom'
})

export const InvitationBlockedReason = Object.freeze({
  INVITATION_LIMIT: 'INVITATION_LIMIT',
  INVITATION_QUOTA: 'INVITATION_QUOTA',
  CONVERSION: 'CONVERSION',
  ALREADY_INVITED: 'ALREADY_INVITED'
})

export const ReadableInvitationBlockedReason = Object.freeze({
  INVITATION_QUOTA: 'Das Limit an Einladungen in Ihrem Vertrag wurde erreicht.',
  INVITATION_LIMIT: 'Das Limit für Einladungen an diesen Kontakt wurde erreicht.',
  CONVERSION: 'Der Kontakt hat das ausgewählte Thema bereits selbst angefordert',
  ALREADY_INVITED: 'Der Kontakt wurde bereits zu dem ausgewählten Thema eingeladen.'
})
