<template>
  <v-dialog
    v-model="dialogOpen"
    transition="dialog-bottom-transition"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="phoneNumbers.length > 1"
        color="primary"
        class="px-1"
        text
        v-on="on"
      >
        (
        <v-icon
          small
          class="ml-1"
        >
          mdi-phone-plus
        </v-icon>
        <span class="mr-1">{{ $t('lead.base-info.phone-list.additional-phones', { amount: phoneNumbers.length-1}) }}</span>
        )
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('lead.base-info.phone-list.title') }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <p class="my-4">
          {{ $t('lead.base-info.phone-list.body-texts.0') }}
          <v-icon small>
            mdi-phone-remove
          </v-icon>

          {{ $t('lead.base-info.phone-list.body-texts.1') }}
          <v-icon small>
            mdi-phone-check
          </v-icon>

          {{ $t('lead.base-info.phone-list.body-texts.2') }}
          <v-icon small>
            mdi-phone-incoming
          </v-icon>

          {{ $t('lead.base-info.phone-list.body-texts.3') }}
          <v-icon small>
            mdi-pencil
          </v-icon>
          {{ $t('lead.base-info.phone-list.body-texts.4') }}
        </p>
      </v-card-text>
      <div class="justify-center mb-4d-flex">
        <div class="d-flex flex-column align-center">
          <div
            v-for="(number, index) of phoneNumbers"
            :key="index"
            class="d-flex phone-list py-2"
          >
            <div class="mr-2">
              <v-icon
                v-if="['CONVERSION','IMPORT'].includes(number.origin) && number.verifiedAt"
                small
              >
                mdi-phone-check
              </v-icon>
              <v-icon
                v-if="number.origin==='CONVERSION' && !number.verifiedAt"
                small
              >
                mdi-phone-settings
              </v-icon>
              <v-icon
                v-if="number.origin==='IMPORT' && !number.verifiedAt"
                small
              >
                mdi-phone-incoming
              </v-icon>
              <v-icon
                v-if="number.origin==='USER'"
                small
              >
                mdi-pencil
              </v-icon>
            </div>
            <a
              :href="`tel:${number.phone}`"
              class="text-body-1"
            >
              {{ number.phone }}
            </a>
          </div>
        </div>
      </div>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="close"
        >
          {{ $t('labels.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    phoneNumbers: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialogOpen: false
  }),
  methods: {
    closeDialog () {
      this.handleDialogClose()
    },
    open () {
      this.dialogOpen = true
    },
    close () {
      this.dialogOpen = false
    }
  }
}

</script>
