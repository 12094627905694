import { render, staticRenderFns } from "./ReturnCall.vue?vue&type=template&id=94c725d2&scoped=true&"
import script from "./ReturnCall.vue?vue&type=script&lang=js&"
export * from "./ReturnCall.vue?vue&type=script&lang=js&"
import style0 from "./ReturnCall.vue?vue&type=style&index=0&id=94c725d2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94c725d2",
  null
  
)

export default component.exports