export const Status = Object.freeze({
  NEW: 'new',
  CREATED: 'created',
  FOLLOW_UP: 'followUp',
  UNREACHED: 'unreached',
  UNREACHED_AT_ALL: 'unreachedAtAll',
  NO_FURTHER_CONTACT: 'noFurtherContact',
  REACHED: 'reached',
  APPOINTMENT_ON_SITE: 'appointmentOnSite',
  ORDER_RECEIVED: 'orderReceived'
})
