<template>
  <v-timeline-item
    color="leadActivity"
    icon="mdi-frequently-asked-questions"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col md="8">
              <div class="mb-2 subtitle-1">
                {{ $t('lead-timeline.quizzard.title', { landingpageName }) }}
              </div>
              <div class="grey--text">
                {{ $t('lead-timeline.quizzard.subtitle') }}: {{ result }}
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-list dense>
            <v-list-item
              v-for="{ question, answer, screenId } in answers"
              :key="screenId"
              class="pa-0"
              dense
            >
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  {{ question }}
                </v-list-item-title>
                <div class="grey--text text--darken-3 body-2 text-wrap">
                  {{ answer }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import QuizPreConversion from '../queries/QuizPreConversion.gql'

export default {
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    landingpageName () {
      return this.quizPreConversion?.landingpage?.name ?? ''
    },
    result () {
      return this.quizPreConversion?.details?.result ?? ''
    },
    answers () {
      return this.quizPreConversion?.details?.answers ?? ''
    }
  },
  apollo: {
    quizPreConversion: {
      query: QuizPreConversion,
      variables () {
        return {
          preConversionId: this.data.details[this.data.type].preConversionId
        }
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
