<template>
  <v-card
    outlined
    class="mt-4 pa-4"
  >
    <h4 class="mb-1 subtitle-2">
      {{ $t('delete-contact.title') }}
    </h4>
    <div
      class="grey--text text--darken-1 caption"
    >
      {{ $t('delete-contact.subtitle') }}
    </div>

    <div class="justify-end mt-3 d-flex">
      <DeleteContact :lead="lead" />
    </div>
  </v-card>
</template>

<script>
import DeleteContact from './DeleteContact'

export default {
  components: {
    DeleteContact
  },
  props: {
    lead: { type: Object, default: null }
  }
}
</script>
