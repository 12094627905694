<template>
  <v-timeline-item
    color="userActivity"
    icon="mdi-account-sync-outline"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col
              md="8"
            >
              <div class="mb-2 subtitle-1">
                {{ title }}
              </div>
              <div class="grey--text">
                {{ subtitle }}
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <div>
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-col
                cols="12"
                md="6"
              >
                {{ $t('lead-timeline.lead-update.body-texts.0') }}
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                {{ $t('lead-timeline.lead-update.body-texts.1') }}
              </v-col>
            </v-row>
            <v-row
              v-for="(update,index) in updates"
              :key="index"
            >
              <v-col
                cols="12"
                md="6"
              >
                <div class="grey--text text--darken-3 body-2 font-weight-bold">
                  {{ update.after }}
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <div class="red--text text--darken-3 body-2 text-decoration-line-through">
                  {{ update.before }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import crmMixin from '@/mixins/crm'

export default {
  mixins: [currencyMixin, crmMixin],

  props: {
    data: { type: Object, default: null }
  },

  computed: {
    changes () {
      return this.data.details.changes
    },
    title () {
      return {
        CRM: this.$t('lead-timeline.lead-update.title-crm', { readableCrmName: this.readableCrmName }),
        IMPORT: this.$t('lead-timeline.lead-update.title-import'),
        CONVERSION: this.$t('lead-timeline.lead-update.title-conversion'),
        USER: this.$t('lead-timeline.lead-update.title-user')
      }[this.changes?.origin]
    },
    subtitle () {
      return {
        CRM: this.$t('lead-timeline.lead-update.subtitle-crm', { readableCrmName: this.readableCrmName }),
        IMPORT: this.$t('lead-timeline.lead-update.subtitle-import'),
        CONVERSION: this.$t('lead-timeline.lead-update.subtitle-conversion'),
        USER: this.$t('lead-timeline.lead-update.subtitle-user')
      }[this.changes?.origin]
    },

    updates () {
      const { __typename: _, ...before } = this.changes?.before || {}
      const { __typename: __, ...after } = this.changes?.after || {}
      if (!before && !after) return []

      const changes = []
      const keys = new Set([...Object.keys(after), ...Object.keys(before)])
      Array.from(keys).forEach(key => {
        if (before[key] || after[key]) {
          if (key === 'isDataProcessingPermissionGranted') {
            changes.push({ before: `${this.$t('labels.gdpr')} ` + (before[key] ? this.$t('labels.accepted') : this.$t('labels.not-accepted')), after: `${this.$t('labels.gdpr')} ` + (after[key] ? this.$t('labels.accepted') : this.$t('labels.not-accepted')) })
          } else {
            changes.push({ before: before[key], after: after[key] })
          }
        }
      })
      return changes
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
