<template>
  <v-timeline-item
    color="systemActivity"
    icon="mdi-trash-can-outline"
    fill-dot
  >
    <v-expansion-panels
      class="expansion-panels"
      accordion
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row
            no-gutters
            align="center"
            class="d-flex justify-center"
            :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
          >
            <v-col
              md="8"
            >
              <div class="mb-2 subtitle-1">
                {{ $t('lead-timeline.crm-lead-deleted.title', { readableCrmName }) }}
              </div>
              <div class="grey--text">
                {{ $t('lead-timeline.crm-lead-deleted.subtitle', { readableCrmName }) }}
              </div>
            </v-col>
            <v-col
              class="pr-2 text-right grey--text"
              :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
              md="4"
            >
              {{ formatDate(data.createdAt) }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-list dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <div>
                      <p>
                        {{ $t('lead-timeline.crm-lead-deleted.body-texts.0', { readableCrmName, readablePlatformName }) }}<br>
                        {{ $t('lead-timeline.crm-lead-deleted.body-texts.1', { readablePlatformName }) }}
                      </p>
                      <DeleteContact :lead="lead" />
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-timeline-item>
</template>
<script>
import crmMixin from '@/mixins/crm'
import brandingMixin from '@/mixins/branding'
import DeleteContact from '../DeleteContact.vue'

export default {
  components: {
    DeleteContact
  },
  mixins: [brandingMixin, crmMixin],
  props: {
    data: { type: Object, default: null },
    leadId: { type: String, default: null },
    leadCrmLink: { type: String, default: null },
    leadOrigin: { type: String, default: null }
  },
  computed: {
    lead () {
      return {
        leadId: this.leadId,
        crmLink: this.leadCrmLink,
        origin: this.leadOrigin
      }
    }
  }
}
</script>

<style scoped>
.expansion-panels {
  margin: -24px -24px 0 -24px;
}
</style>
