<template>
  <v-card
    v-if="lead"
    class="mt-4 pa-4"
    outlined
  >
    <h4 class="mb-1 subtitle-2">
      {{ $t('lead.base-info.mail-info.title') }}
    </h4>

    <v-chip
      v-for="subscription in lead.subscriptions"
      :key="subscription.id"
      class="mt-2 mr-2"
    >
      <v-icon
        v-if="subscription.unsubscribedAt"
        color="red"
        left
      >
        mdi-stop-circle
      </v-icon>
      <v-icon
        v-else-if="lead.confirmedAt"
        color="success"
        left
      >
        mdi-check-circle
      </v-icon>
      <v-icon
        v-else
        color="orange"
        left
      >
        mdi-clock
      </v-icon>
      {{ subscription.campaign.name }}
    </v-chip>
    <p v-if="!hasSubscriptionsOrChannel">
      {{ $t('lead.base-info.mail-info.no-subscriptions') }}
    </p>
    <div class="justify-end mt-6 d-flex">
      <Permission permission="lead:unsubscribe">
        <v-btn
          small
          color="primary"
          :disabled="!hasSubscriptionsOrChannel"
          @click.prevent="unsubscribe"
        >
          {{ $t('buttons.lead.mail-info.cta') }}
        </v-btn>
      </Permission>
    </div>
  </v-card>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import STOP_ALL_CAMPAIGNS_FOR_LEAD from './queries/StopAllCampaignsForLead.gql'
import Permission from '@/components/Permission'

export default {
  components: { Permission },
  props: {
    lead: { type: Object, default: null }
  },
  computed: {
    hasSubscriptionsOrChannel () {
      return Boolean(this.lead.subscriptions.filter(({ subscribedAt, unsubscribedAt }) => subscribedAt && !unsubscribedAt).length)
    }
  },
  methods: {
    async unsubscribe () {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: STOP_ALL_CAMPAIGNS_FOR_LEAD,
          variables: {
            input: {
              leadId: this.lead.id
            }
          }
        })
        this.$emit('unsubscribed')
        if (data.unsubscribeLead) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.lead.mail-info.success') })
        } else {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.lead.mail-info.error') })
        }
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.lead.mail-info.error') })
      }
    }
  }
}
</script>
