<template>
  <v-select
    v-if="company && landingpageSlugs"
    ref="landingpageSlug"
    :value="value"
    class="mt-2"
    outlined
    :label="$t('labels.acquisition-booster.topic-selection')"
    validate-on-blur
    :items="landingpageSlugs"
    item-value="slug"
    item-text="name"
    dense
    required
    @change="onChange"
  />
</template>

<script>
import LANDINGPAGE_TEMPLATES_FOR_COMPANY from '@/queries/LandingpageTemplatesForCompany.gql'
import LANDINGPAGES from '@/modules/leads/Landingpages.gql'
import BLOCKED_LANDINGPAGES_SLUGS_FOR_INVITATION from '@/modules/leads/queries/BlockedLandingpagesSlugsForInvitation.gql'

// These are non-relevant topics according to Axel and should't be available for invitations
const NON_RELEVANT_TOPICS = [
  'eigentums-radar',
  'was-gehoert-in-ein-expose',
  'unterlagen-fuer-den-verkauf',
  'aufbereitung-der-immobilie',
  'energieausweis',
  'immobilie-geerbt-checkliste',
  'bewirtschaftung-einer-immobilie-checkliste'
]

export default {
  props: {
    leadId: { type: String, default: null },
    value: { type: String, default: null }
  },
  data () {
    return {
      landingpageTemplates: []
    }
  },
  computed: {
    landingpages () {
      return this?.company?.landingpages
        ? this.company.landingpages.filter(({ slug }) =>
          this.$features.feature('lp-' + slug)?.isActive && !NON_RELEVANT_TOPICS.includes(slug))
        : []
    },
    landingpageSlugs () {
      const filteredLandingpageTemplates = this.landingpageTemplates.filter(({ slug }) => !slug.startsWith('ms-'))
      const disabledLandingpageSlugs = filteredLandingpageTemplates.map(lpTemplate => ({ ...lpTemplate, disabled: true }))
      const all = [...this.landingpages, ...disabledLandingpageSlugs]
      const tools = all.filter(lp => lp.type !== 'GUIDE' && lp.type !== 'CHECKLIST')
      const guides = all.filter(lp => lp.type === 'GUIDE')
      const checklists = all.filter(lp => lp.type === 'CHECKLIST')

      const slugs = [{ header: 'Tools' }, ...tools]
      if (guides.length > 0) { slugs.push({ divider: true }, { header: 'Ratgeber (Expert)' }, ...guides) }
      if (checklists.length > 0) { slugs.push({ divider: true }, { header: 'Checklisten (Expert)' }, ...checklists) }

      if (this.blockedLandingpagesSlugs) {
        return slugs
          .map((lpTemplate) => ({
            ...lpTemplate,
            disabled: lpTemplate.disabled || this.blockedLandingpagesSlugs?.includes(lpTemplate.slug)
          }))
          .sort((a, b) => Number(a.disabled) - Number(b.disabled))
      }
      return slugs
    }
  },
  methods: {
    onChange (value) {
      this.$emit('input', value)
    }
  },
  apollo: {
    landingpageTemplates: {
      query: LANDINGPAGE_TEMPLATES_FOR_COMPANY,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            onlyDefault: true
          }
        }
      }
    },
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    },
    blockedLandingpagesSlugs: {
      query: BLOCKED_LANDINGPAGES_SLUGS_FOR_INVITATION,
      skip () {
        return !this.leadId
      },
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            leadId: this.leadId
          }
        }
      }
    }
  }
}
</script>
