<template>
  <v-card
    class="mt-4 pa-4"
    outlined
  >
    <h4 class="mb-1 subtitle-2">
      {{ $t('labels.tags') }}
    </h4>

    <div
      v-for="category in Object.keys(categories)"
      :key="category"
    >
      <h5 class="mb-1 body-2 font-weight-bold">
        {{ $t('labels.category') }}: {{ category }} ({{ categories[category].length }})
      </h5>
      <v-chip
        v-for="tag in categories[category]"
        :key="tag"
        class="mt-2 mr-2"
      >
        {{ prepareTag(tag) }}
      </v-chip>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    lead: { type: Object, default: null }
  },
  computed: {
    categories () {
      const categories = {}
      this.lead.tags.filter(tag => tag.startsWith('crm:')).forEach(tag => {
        const category = tag.split(':')[0]
        if (!categories[category]) {
          categories[category] = []
        }
        categories[category].push(tag)
      })
      return categories
    }
  },
  methods: {
    prepareTag (tag) {
      const tagNameArray = tag.split(':')
      return tagNameArray[tagNameArray.length - 1]
    }
  }
}
</script>
