import Accessibility from '@/lib/enums/propertyRequest/Accessibility'
import AvailableStartDate from '@/lib/enums/propertyRequest/AvailableStartDate'
import Financing from '@/lib/enums/propertyRequest/Financing'
import LivingSituation from '@/lib/enums/propertyRequest/LivingSituation'
import OutdoorArea from '@/lib/enums/propertyRequest/OutdoorArea'
import ResidentialArea from '@/lib/enums/propertyRequest/ResidentialArea'
import Surroundings from '@/lib/enums/propertyRequest/Surroundings'

export const AccessibilityLabels = {
  [Accessibility.REQUIRED]: 'property-request.accessibility-labels.required',
  [Accessibility.OPTIONAL]: 'property-request.accessibility-labels.optional',
  [Accessibility.NOT_REQUIRED]: 'property-request.accessibility-labels.not-required'
}
export const AvailableStartDateLabels = {
  [AvailableStartDate.EARLIEST]: 'property-request.available-start-date-labels.earliest',
  [AvailableStartDate.LESS_THAN_6_MONTH]: 'property-request.available-start-date-labels.less-than-six',
  [AvailableStartDate.MORE_THAN_6_MONTH]: 'property-request.available-start-date-labels.more-than-six',
  [AvailableStartDate.DEPENDING_ON_OFFER]: 'property-request.available-start-date-labels.depending-on-offer',
  [AvailableStartDate.DEPENDING_ON_SALE]: 'property-request.available-start-date-labels.depending-on-sale'
}
export const FinancingLabels = {
  [Financing.CLEAR]: 'property-request.financing-labels.clear',
  [Financing.CLARIFICATION]: 'property-request.financing-labels.clarification',
  [Financing.UNCLEAR]: 'property-request.financing-labels.unclear',
  [Financing.CONSULTING_NEEDED]: 'property-request.financing-labels.consulting-needed'
}
export const LivingSituationLabels = {
  [LivingSituation.RENT]: 'property-request.living-situation-labels.rent',
  [LivingSituation.RENT_FREE]: 'property-request.living-situation-labels.rent-free',
  [LivingSituation.OWNER]: 'property-request.living-situation-labels.owner'
}
export const OutdoorAreaLabels = {
  [OutdoorArea.BALCONY]: 'property-request.outdoor-area-labels.balcony',
  [OutdoorArea.TERRACE]: 'property-request.outdoor-area-labels.terrace',
  [OutdoorArea.TERRACE_WITH_GARDEN]: 'property-request.outdoor-area-labels.terrace-with-garden',
  [OutdoorArea.GARDEN]: 'property-request.outdoor-area-labels.garden'
}
export const ResidentialAreaLabels = {
  [ResidentialArea.QUIET]: 'property-request.residential-area-labels.quiet',
  [ResidentialArea.URBAN]: 'property-request.residential-area-labels.urban',
  [ResidentialArea.RURAL]: 'property-request.residential-area-labels.rural'
}
export const SurroundingsLabels = {
  [Surroundings.HEALTHCARE]: 'property-request.surroundings-labels.healthcare',
  [Surroundings.EDUCATION]: 'property-request.surroundings-labels.education',
  [Surroundings.SHOPPING]: 'property-request.surroundings-labels.shopping',
  [Surroundings.GASTRONOMY]: 'property-request.surroundings-labels.gastronomy',
  [Surroundings.PUBLIC_TRANSPORT]: 'property-request.surroundings-labels.public-transport'
}
