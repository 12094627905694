<template>
  <v-timeline-item
    v-if="ACTIVITIES[data.type]"
    :color="color"
    :icon="activity.icon"
    fill-dot
  >
    <v-row
      no-gutters
      align="center"
      class="pr-12 mb-2 mt-n2 d-flex justify-center"
      :class="{'flex-column':$vuetify.breakpoint.smAndDown}"
    >
      <v-col md="8">
        <div class="subtitle-1">
          {{ $t(activity.title, { campaign: activity.campaign }) }}
        </div>
        <div class="grey--text text--small">
          <span v-if="initiator"> {{ $t('lead-timeline.channel-activity.initiated-by') }} {{ initiator }}</span>
        </div>
      </v-col>
      <v-col
        class="text-right grey--text text--small pr-8"
        :class="{'text-start mt-2':$vuetify.breakpoint.smAndDown}"
        md="4"
      >
        {{ formatDate(data.createdAt) }}
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import brandingMixin from '@/mixins/branding'

const COLORS = {
  SYSTEM: 'systemActivity',
  MAKLER: 'userActivity',
  LEAD: 'leadActivity'
}

const ACTIVITIES = {
  FUNNEL_UNSUBSCRIBED: ({ campaigns = [] }) => ({
    icon: 'mdi-email-outline',
    title: campaigns.length === 1 && campaigns[0].name ? 'lead-timeline.channel-activity.funnel-unsubscribed.one-funnel' : 'lead-timeline.channel-activity.funnel-unsubscribed.all-funnels',
    campaign: campaigns.length === 1 && campaigns[0].name ? campaigns[0].name : '',
    type: 'LEAD'
  }),
  CHANNEL_RESUBSCRIBED: () => ({
    icon: 'mdi-account-reactivate',
    title: 'lead-timeline.channel-activity.channel-resubscribed',
    type: 'MAKLER'
  }),
  CHANNEL_UNSUBSCRIBED: () => ({
    icon: 'mdi-account-remove',
    title: 'lead-timeline.channel-activity.channel-unsubscribed',
    type: 'LEAD'
  }),
  CHANNEL_SUBSCRIBED: () => ({
    icon: 'mdi-account-plus',
    title: 'lead-timeline.channel-activity.channel-subscribed',
    type: 'LEAD'
  })
}

export default {
  mixins: [brandingMixin],
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      message: null
    }
  },
  computed: {
    activity () {
      return ACTIVITIES[this.data.type](this.data.details)
    },
    color () {
      return this.data.user ? COLORS.MAKLER : COLORS[this.activity.type]
    },
    initiator () {
      return {
        MAKLER: this.data.user
          ? `${this.data.user.firstname} ${this.data.user.lastname}`
          : this.$t('lead-timeline.channel-activity.initiator'),
        BACKOFFICE_USER: `${this.readablePlatformName} ${this.$t('lead-timeline.channel-activity.initiator')}`
      }[this.data.initiator]
    }
  },
  created () {
    this.ACTIVITIES = ACTIVITIES
  }
}
</script>

<style scoped>
.text--small {
  font-size: 15px;
}
</style>
