<template>
  <v-card
    class="mt-4 pa-4"
    outlined
  >
    <h4 class="mb-1 subtitle-2">
      {{ $t('lead-list.filter-settings.labels.status') }}
    </h4>
    <Permission
      v-slot="{hasPermission}"
      permission="lead:write"
      show
    >
      <v-select
        v-model="selectedStatus"
        :items="statusList"
        :item-disabled="disabledStatus"
        :menu-props="{ maxHeight: 340 }"
        hide-details
        outlined
        dense
        :disabled="!hasPermission"
        @change="handleStatusChange"
      />
    </Permission>

    <Feature :feature-slug="featureNames.ASSIGN_LEADS">
      <Permission permission="lead:assign">
        <div>
          <h4 class="mt-4 mb-1 subtitle-2">
            {{ $t('lead-list.filter-settings.labels.assigned-employee') }}
          </h4>
          <v-select
            v-model="selectedUserId"
            :items="usersForSelect"
            placeholder="keiner zugewiesen"
            hide-details
            outlined
            dense
            clearable
            @change="handleAssignedUserChange"
          />
        </div>
      </Permission>
    </Feature>
  </v-card>
</template>

<script>
import { statusForSelect } from '@/lib/leadStatus'
import { sortByString } from '@/lib/sort-functions'
import LEAD from './queries/Lead.gql'
import USERS from '../queries/Users.gql'
import ASSIGN_USER from './queries/AssignUser.gql'
import UNASSIGN_USER from './queries/UnassignUser.gql'
import UPDATE_LEAD_STATUS from './queries/UpdateLeadStatus.gql'
import bus, { eventNames } from '@/lib/eventBus'
import Permission from '@/components/Permission'
import { Feature } from 'feature-config'
import feature from '@/mixins/feature'

export default {
  components: { Permission, Feature },
  mixins: [feature],
  inject: ['role'],
  props: {
    status: {
      type: String,
      default: 'new'
    },
    leadId: {
      type: String,
      default: null
    },
    assignedUserId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selectedStatus: this.status,
      selectedUserId: this.assignedUserId,
      statusList: statusForSelect.map(item => { // create translated status list
        return {
          ...item,
          text: this.$t(item.text)
        }
      })
    }
  },
  computed: {
    usersForSelect () {
      return (this.users ?? [])
        .map(user => ({
          value: user.id,
          text: `${user.firstname} ${user.lastname}`
        }))
        .sort(sortByString('text'))
    }
  },
  watch: {
    status () {
      this.selectedStatus = this.status
    }
  },
  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      skip () {
        return !this.role.isAuthorizedFor('user:read')
      }
    }
  },
  methods: {
    disabledStatus ({ value }) {
      return this.status !== 'created' && value === 'created'
    },
    async handleStatusChange () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LEAD_STATUS,
          variables: {
            leadId: this.leadId,
            status: this.selectedStatus
          },
          refetchQueries: [
            { query: LEAD, variables: { id: this.leadId } }
          ]
        })
        this.showSuccess(this.$t('alerts.lead.status.success'))
        this.triggerAnimation(this.selectedStatus)
      } catch (e) {
        this.selectedStatus = this.status
        this.showError(this.$t('alerts.lead.status.error'))
      }
    },
    async handleAssignedUserChange () {
      try {
        if (this.selectedUserId) {
          await this.assignUser()
        } else {
          await this.unassignUser()
        }
        this.showSuccess(this.$t('alerts.lead.status.succes-user'))
      } catch (e) {
        this.selectedUserId = this.assignedUserId
        this.showError(this.$t('alerts.lead.status.error-user'))
      }
    },
    assignUser () {
      return this.$apollo.mutate({
        mutation: ASSIGN_USER,
        variables: {
          leadId: this.leadId,
          userId: this.selectedUserId
        }
      })
    },
    unassignUser () {
      return this.$apollo.mutate({
        mutation: UNASSIGN_USER,
        variables: {
          leadId: this.leadId
        }
      })
    },
    showSuccess (message) {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: message })
    },
    showError (message) {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: message })
    },
    triggerAnimation (selectedStatus) {
      const showAnimationStates = ['reached', 'orderReceived']
      if (showAnimationStates.includes(selectedStatus)) {
        bus.$emit(eventNames.TRIGGER_ANIMATION)
      }
    }
  }
}

</script>
