<template>
  <v-expansion-panels
    class="mb-4"
    :style="cssProps"
    flat
  >
    <v-expansion-panel
      class="expansion-panel-border"
    >
      <v-expansion-panel-header>
        <div color="info">
          <v-icon
            color="info"
            class="mr-2"
          >
            mdi-information-outline
          </v-icon>
          {{ $t('labels.recommendations') }} <b>{{ $t(readableStatus) }}</b>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p
          style="white-space: pre-line"
          v-html="infoText"
        />
        <p
          v-if="appLink"
        >
          {{ appLink.text }}
          <router-link :to="appLink.link">
            {{ appLink.linkText }}
          </router-link>
        </p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import recommendations from '@/lib/leadStatusRecommendation'
import leadStatus from '@/lib/leadStatus'

export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    readableStatus () {
      return leadStatus[this.status]
    },
    infoText () {
      return recommendations[this.status].text[this.$i18n.locale]
    },
    appLink () {
      return recommendations[this.status].appLink ? recommendations[this.status].appLink[this.$i18n.locale] : false
    },
    cssProps () {
      return {
        '--info-color': this.$vuetify.theme.themes.light.info
      }
    }
  }
}
</script>
