<template>
  <div class="w-full">
    <div
      v-if="isLoading"
      class="d-flex flex-column align-center justify-center mb-4"
    >
      <GenericAnimation
        :animation-data="animationData"
        loop
        autoplay
        :width="100"
        :speed="0.5"
      />

      <span
        v-if="leadIds.length > 1"
        class="text-center"
      >
        {{ $t('alerts.acquisition-booster.invitation-button.prepare-invitation') }}
      </span>
      <span
        v-else
        class="text-center"
      >
        {{ $t('alerts.acquisition-booster.invitation-button.send-invitation') }}
      </span>
    </div>

    <v-tooltip
      bottom
      :disabled="!!landingpageSlug && leadIds.length > 0"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn
            class="w-full primary my-2"
            small
            :disabled="isLoading || !landingpageSlug || leadIds.length === 0"
            :loading="isLoading"
            @click="submit"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-rocket-launch
            </v-icon>
            {{ $t('buttons.acquisition-booster.invitation-button.cta') }}
          </v-btn>
        </div>
      </template>
      <span v-if="!landingpageSlug">
        {{ $t('alerts.acquisition-booster.invitation-button.error-topic') }}
      </span>
      <span v-else-if="leadIds.length === 0">
        {{ $t('alerts.acquisition-booster.invitation-button.error-contacts') }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import GenericAnimation from '@/components/GenericAnimation.vue'
import bus, { eventNames } from '@/lib/eventBus'
import INVITE_LEADS_TO_LANDINGPAGE from '@/modules/leads/queries/InviteLeadsToLandingpage.gql'
import AnimationData from '../../../../public/animations/email-plane.json'

export default {
  components: { GenericAnimation },
  props: {
    landingpageSlug: {
      type: String,
      default: null
    },
    delay: {
      type: Number,
      default: 7000
    },
    leadIds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isLoading: false,
      animationData: AnimationData
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.loadingTimeout = setTimeout(() => {
        this.inviteLeads()
      }, this.delay)
    },
    async inviteLeads () {
      try {
        await this.$apollo.mutate({
          mutation: INVITE_LEADS_TO_LANDINGPAGE,
          variables: {
            input: {
              leadIds: this.leadIds,
              landingpageSlug: this.landingpageSlug
            }
          }
        })
        bus.$emit(eventNames.TRIGGER_ANIMATION)
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.acquisition-booster.success') })
        this.$emit('invitationSent')
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.acquisition-booster.error') })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
