import { appBranding, partner, lpEigentumsRadar } from '@/lib/features'

const status = {
  created: {
    text: {
      de: `Sehr gut, der Kontakt ist im ${appBranding?.isActive && appBranding?.config?.readablePlatformName} System.
      ${lpEigentumsRadar?.isActive ? `Um erneut mit dem Kontakt ins Gespräch zu kommen, laden Sie den Kontakt zum Eigentums-Radar ein.
      Damit geben Sie Ihrem Kontakt Zugang zu spannenden Informationen und Daten rund um seine Immobilie und schaffen so die Grundlage für Gespräche.` : ''}`,
      en: `Very good, the contact is in the ${appBranding?.isActive && appBranding?.config?.readablePlatformName} system.
      ${lpEigentumsRadar?.isActive ? `To start a conversation with the contact again, invite the contact to the Property Radar.
      This gives your contact access to exciting information and data about their property and thus lays the foundation for conversations.` : ''}`
    }
  },

  new: {
    text: {
      de: `Sehr gut, ein neuer Kontakt ist da. Jetzt sind Sie gefragt.
      Ihre Mission: Erstgespräch!
      Hier ein paar Tipps für den optimalen Gesprächseinstieg:
      1) Bewertung (telefonisch):
      &nbsp; - Preis ok? Etwas anderes erwartet? Zu niedrig? Zu hoch?
      &nbsp; - Online kann nur Durchschnitt - wir können mehr.
      &nbsp; - Stadtteil, Straße, Immobilie kenne ich.
      2) Ratgeber (per Mail oder Videobotschaft):
      &nbsp; - Kenne das Thema gut, erst gerade wieder einen Fall.
      &nbsp; - Gutes lokales Netzwerk in dem Bereich vorhanden.`,
      en: `Very good, a new contact is here. Now it's your turn.
      Your mission: Initial conversation!
      Here are a few tips for the optimal conversation start:
      1) Evaluation (by phone):
      &nbsp; - Price ok? Expected something else? Too low? Too high?
      &nbsp; - Online can only do average - we can do more.
      &nbsp; - I know the district, street, property.
      2) Guide (by email or video message):
      &nbsp; - I know the topic well, just had a case again.
      &nbsp; - Good local network available in this area.`
    }
  },

  followUp: {
    text: {
      de: `Sie konnten den Kontakt nicht erreichen. Macht nichts, versuchen Sie es zu einem anderen Zeitpunkt erneut und bleiben Sie dran.
      Machen Sie ggfs. Notizen im Kommentarfeld. Dies kann bei der Vorbereitung auf das nächste Gespräch hilfreich sein.`,
      en: `You could not reach the contact. No problem, try again at a different time and stay tuned.
      If necessary, make notes in the comment field. This can be helpful in preparing for the next conversation.`
    }
  },

  unreached: {
    text: {
      de: `Sie haben es telefonisch versucht, aber leider erfolglos. Sie bekommen den Kontakt einfach nicht ans Ohr.
      Nicht aufgeben, bleiben Sie dran!  Die Telefonnummer ist falsch? Dann versuchen Sie es postalisch über die Adresse der Immobilie.`,
      en: `You tried to reach them by phone, but unfortunately without success. You just can't get the contact on the phone.
      Don't give up, keep going! Is the phone number wrong? Then try it by mail using the property's address.`
    },
    appLink: {
      de: {
        text: 'Anregungen für ein entsprechendes Anschreiben finden Sie im Bereich',
        link: '/landingpages#templates',
        linkText: 'Vorlagen'
      },
      en: {
        text: 'Suggestions for a corresponding cover letter can be found in the section',
        link: '/landingpages#templates',
        linkText: 'Templates'
      }
    }
  },

  unreachedAtAll: {
    text: {
      de: 'Sie haben alles versucht, aber keine Chance? Vielleicht können Sie postalisch an den Eigentümer drankommen. ',
      en: "You've tried everything, but no chance? Maybe you can reach the owner by mail."
    },
    appLink: {
      de: {
        text: 'Das entsprechende Anschreiben finden Sie unter',
        link: '/landingpages#templates',
        linkText: 'Landingpages & Köder'
      },
      en: {
        text: 'You can find the corresponding cover letter under',
        link: '/landingpages#templates',
        linkText: 'Landingpages & Bait'
      }
    }
  },

  noFurtherContact: {
    text: {
      de: `Kopf hoch, Krone richten, weitermachen.
      In jedem Fall haben Sie Ihre Marke und Ihre Expertise einem Eigentümer präsentiert. Vielleicht ja ein anderes Mal.`,
      en: `Keep your head up, adjust your crown, and keep going.
      In any case, you have presented your brand and your expertise to a property owner. Maybe another time.`
    }
  },

  reached: {
    text: {
      de: `Glückwunsch, das erste und wichtigste Etappenziel ist erreicht: Sie haben den Termin.
      Bereiten Sie sich möglichst gut vor: Gibt es Vergleichsobjekte, ähnliche Kunden und Konstellationen, gemeinsame Kontakte oder Interessen?`,
      en: `Congratulations, the first and most important milestone has been reached: You have the appointment.
      Prepare as well as possible: Are there comparable objects, similar customers and constellations, common contacts or interests?`
    }
  },

  appointmentOnSite: {
    text: {
      de: `Der persönliche Termin entscheidet alles. Daher: bereiten Sie sich gut vor und treten Sie authentisch auf.
      Punkten Sie mit Persönlichkeit und lokaler Expertise. Bringen Sie einen passenden Ratgeber ausgedruckt mit - das zeigt Wertschätzung und Kompetenz.
      Tipp: Jede Art von Aufmerksamkeit wird Ihnen einen Vorteil verschaffen, denn Geschenke bringen Ihren Gegenüber in einen unbewussten Zugzwang.`,
      en: `The personal appointment decides everything. Therefore: prepare well and appear authentic.
      Score with personality and local expertise. Bring a suitable guide printed out - this shows appreciation and competence.
      Tip: Any kind of attention will give you an advantage, because gifts put your counterpart in an unconscious compulsion to reciprocate.`
    }
  },

  orderReceived: {
    text: {
      de: `Stark! Sie haben den Auftrag. In der Vermarktung sind Sie fit!
      ${partner?.isActive ? ` Und wenn Sie noch stärker werden wollen, empfehlen wir unseren Partner Imogent.
      Imogent hat sich auf Dienstleistungen rund um die Vermarktung spezialisiert.
      Für ${appBranding?.isActive && appBranding?.config?.readablePlatformName} Kunden gibt es vergünstigte Konditionen.` : ''}`,
      en: `Great! You have the order. You are fit in marketing!
      ${partner?.isActive ? ` And if you want to become even stronger, we recommend our partner Imogent.
      They have specialized in services around marketing.
      For ${appBranding?.isActive && appBranding?.config?.readablePlatformName} customers, there are discounted conditions.` : ''}`
    },
    ...(partner?.isActive && {
      appLink: {
        de: {
          text: 'Hier Vorteile entdecken.',
          link: '/partner'
        },
        en: {
          text: 'Discover advantages here.',
          link: '/partner'
        }
      }
    })
  }
}

export default status
